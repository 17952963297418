import { effect, inject, untracked } from '@angular/core';
import { DeliveryDateTime, DeliveryTimeSlot } from '@app/core/entities/delivery.interface';
import { DeliveryService } from '@app/services/delivery.service';
import { AddressRepository } from '@app/state/address.repository';
import { mergeDateAndTime, toUTCDate } from '@app/utils/date-utils';
import { tapResult } from '@app/utils/tap-result';
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap } from 'rxjs';

type DeliveryRepository = {
  day: Date | null;
  timeSlot: DeliveryTimeSlot | null;
  deliveryAmount: number;
  minimalAmountFreeDelivery: number;
};

const hasDeliveryTimeChanged = (
  fromStore: { day: Date | null; timeSlot: DeliveryTimeSlot | null },
  response: DeliveryDateTime,
) => {
  if (fromStore.day && fromStore.timeSlot) {
    return (
      toUTCDate(fromStore.day).getTime() !== toUTCDate(response.date).getTime() &&
      fromStore.timeSlot.timeStart !== response.timeStart &&
      fromStore.timeSlot.timeEnd !== response.timeEnd
    );
  }
  return true;
};

export const DeliveryRepository = signalStore(
  { providedIn: 'root' },
  withState<DeliveryRepository>({
    day: null,
    timeSlot: null,
    deliveryAmount: 0,
    minimalAmountFreeDelivery: 0,
  }),
  withMethods((store, deliveryService = inject(DeliveryService), addressRepository = inject(AddressRepository)) => ({
    getFirstAvailable() {
      const addressId = addressRepository.address()?.id;
      deliveryService.getFirstAvailableTime(addressId).subscribe((deliveryDateTime) => {
        if (
          hasDeliveryTimeChanged({ day: untracked(store.day), timeSlot: untracked(store.timeSlot) }, deliveryDateTime)
        ) {
          patchState(store, () => ({
            day: toUTCDate(deliveryDateTime.date),
            timeSlot: {
              timeStart: deliveryDateTime.timeStart,
              timeEnd: deliveryDateTime.timeEnd,
              available: deliveryDateTime.available,
            },
          }));
        }
      });
    },
    getDeliveryAmount: rxMethod<{ addressId: number; date: Date }>(
      pipe(
        switchMap((data) => deliveryService.getDeliveryAmount(data.addressId, data.date)),
        tapResult((amount) => {
          patchState(store, {
            deliveryAmount: amount.amount || 0,
            minimalAmountFreeDelivery: amount.freeDeliveryStartFrom || 0,
          });
        }),
      ),
    ),
    setDay(day: Date) {
      patchState(store, () => ({ day }));
    },
    setTimeSlot(timeSlot: DeliveryTimeSlot) {
      patchState(store, () => ({
        timeSlot,
      }));
    },
  })),
  withHooks({
    onInit(store, addressRepository = inject(AddressRepository)) {
      effect(() => {
        addressRepository.address();
        store.getFirstAvailable();
      });
      effect(() => {
        const address = addressRepository.address();
        if (addressRepository.isPickup()) {
          patchState(store, { deliveryAmount: 0, minimalAmountFreeDelivery: 0 });
          return;
        }
        const day = store.day();
        const time = store.timeSlot()?.timeStart;
        if (address && day && time) {
          store.getDeliveryAmount({ addressId: address.id, date: mergeDateAndTime(day, time) });
        }
      });
    },
  }),
);
