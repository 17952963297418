import { computed, effect, inject } from '@angular/core';
import { Address, IAddAddress } from '@app/core/entities/address.interface';
import { AuthService } from '@app/modals/auth/auth.service';
import { tapResult } from '@ngneat/effects';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { AddressService } from '@services/address.service';
import { useSiteConfig } from '@services/site-config.service';

type AddressesStore = {
  allAddresses: Address[];
  currentAddressId: number | null;
  isLoading: boolean;
  isPickup: boolean;
  isDeliveryAvailable: boolean;
  isPickupAvailable: boolean;
};

export const AddressRepository = signalStore(
  { providedIn: 'root' },
  withState<AddressesStore>({
    allAddresses: [],
    currentAddressId: null,
    isLoading: false,
    isPickup: false,
    isDeliveryAvailable: true,
    isPickupAvailable: true,
  }),
  withMethods((store, addressService = inject(AddressService)) => ({
    loadAll() {
      patchState(store, (state) => ({ isLoading: true }));
      addressService.getAllAddresses().subscribe((addresses) => {
        patchState(store, (state) => ({ allAddresses: addresses, isLoading: false }));
      });
    },
    updateAddress(address: Address) {
      return addressService.putAddress(address).pipe(
        tapResult((newAddress) => {
          if (address.id === store.currentAddressId()) {
            patchState(store, (state) => ({ currentAddressId: newAddress.id }));
          }
          this.loadAll();
        }),
      );
    },
    addAddress(address: IAddAddress) {
      patchState(store, { isPickup: false });
      return addressService.addAddress(address).pipe(tapResult(() => this.loadAll()));
    },
    deleteAddress(address: Address) {
      return addressService.deleteAddress(address.id).pipe(tapResult(() => this.loadAll()));
    },
    setAddress(address: Address) {
      return patchState(store, (state) => ({ currentAddressId: address.id, isPickup: false }));
    },
    setIsPickup() {
      return patchState(store, {
        isPickup: store.isPickupAvailable(),
      });
    },
    setDelivery() {
      return patchState(store, {
        isPickup: !store.isDeliveryAvailable(),
      });
    },
  })),
  withComputed(({ allAddresses, currentAddressId, isDeliveryAvailable, isPickupAvailable, isPickup }) => ({
    address: computed((): Address | undefined => {
      const id = currentAddressId();
      if (isPickup()) {
        return undefined;
      }
      if (id) {
        return allAddresses().find((address) => address.id === id);
      }
      return allAddresses().find((address) => address.isDefault);
    }),
    isPickupAndDeliveryAvailable: computed(() => isDeliveryAvailable() && isPickupAvailable()),
  })),
  withHooks({
    onInit(store, authService = inject(AuthService), siteConfig = useSiteConfig()) {
      authService.user$.subscribe(() => store.loadAll());
      effect(
        () => {
          const config = siteConfig();
          if (config) {
            patchState(store, {
              isPickupAvailable: config.isOrderPickUpPossible,
              isDeliveryAvailable: config.isOrderDeliveryPossible,
            });

            if (!store.isPickupAndDeliveryAvailable()) {
              patchState(store, { isPickup: config.isOrderPickUpPossible });
            }
          }
        },
        { allowSignalWrites: true },
      );
    },
  }),
);
