const userTimezoneOffset = () => new Date().getTimezoneOffset() * 60000;

export const trimSeconds = (time: string) => {
  const [hours, minutes] = time.split(':');
  return `${hours}:${minutes}`;
};

export const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

export const isSameTime = (time1: Date, time2: Date): boolean => {
  return time1.getHours() === time2.getHours() && time1.getMinutes() === time2.getMinutes();
};

export const toUTCDate = (date: string | Date) => {
  const localDate = new Date(date);
  return new Date(localDate.getTime() + userTimezoneOffset());
};

export const toLocalDate = (date: string | Date) => {
  const utcDate = new Date(date);
  return new Date(utcDate.getTime() - userTimezoneOffset());
};

export const mergeDateAndTime = (date: Date, time: string) => {
  const resultDate = new Date(date);
  const [hours, minutes] = time.split(':');
  resultDate.setHours(+hours, +minutes);
  return resultDate;
};

export const isToday = (date: Date) => {
  return isSameDay(toUTCDate(new Date()), date);
};

export const getYesterday = () => {
  const date = toUTCDate(new Date());
  date.setDate(date.getDate() - 1);
  return date;
};

export const isTomorrow = (date: Date) => {
  const today = toUTCDate(new Date());
  today.setDate(today.getDate() + 1);
  return isSameDay(today, date);
};
