import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_DELIVERY,
  API_DELIVERY_FIRST_AVAILABLE,
  API_DELIVERY_NEXT_AVAILABLE,
  API_ORDER_DELIVERY_AMOUNT,
} from '@app/constants/api-path.constant';
import {
  DeliveryAmount,
  DeliveryDateTime,
  DeliveryDay,
  DeliverySlotsByDay,
} from '@app/core/entities/delivery.interface';
import { toLocalDate } from '@app/utils/date-utils';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  constructor(private http: HttpClient) {}

  getDeliveryAmount(addressId: number, date: Date) {
    return this.http.get<DeliveryAmount>(environment.baseUrl + API_ORDER_DELIVERY_AMOUNT, {
      params: {
        addressId,
        deliveryDate: toLocalDate(date).toISOString(),
      },
    });
  }

  getDeliveryTime(day: Date, addressId?: number, city?: string): Observable<DeliverySlotsByDay> {
    const date = new Date(day).toDateString().slice(0, 15);
    let params: any = { date };
    if (city) params = { ...params, city };
    if (addressId) params = { ...params, addressId };
    return this.http.get<DeliverySlotsByDay>(environment.baseUrl + API_DELIVERY, {
      params,
    });
  }

  getFirstAvailableTime(addressId?: number, city?: string): Observable<DeliveryDateTime> {
    let params: any = {};
    if (city) params = { ...params, city };
    if (addressId) params = { ...params, addressId };
    return this.http.get<DeliveryDateTime>(environment.baseUrl + API_DELIVERY_FIRST_AVAILABLE, { params });
  }

  getNextAvailableDays(addressId?: number, city?: string): Observable<DeliveryDay[]> {
    let params: any = {};
    if (city) params = { ...params, city };
    if (addressId) params = { ...params, addressId };
    return this.http.get<DeliveryDay[]>(environment.baseUrl + API_DELIVERY_NEXT_AVAILABLE, { params });
  }
}
