import { DestroyRef, inject } from '@angular/core';
import { Subscription, TeardownLogic } from 'rxjs';

/**
 * Helper funciton to collapse subscriptions into one
 *
 * @example
 * ```
 * const subscription = subscribe([
 *   of(1).subscribe(),
 *   of(2).subscribe(),
 *   of(3).subscribe(),
 * ]);
 *
 * subscription.unsubscribe();
 *
 * ```
 */
export function subscribe(subscriptions: TeardownLogic[]) {
  const subscription = new Subscription();
  const destroyRef = inject(DestroyRef);

  subscriptions.forEach(subscription.add.bind(subscription));

  destroyRef.onDestroy(() => {
    subscription.unsubscribe();
  });

  return subscription;
}
