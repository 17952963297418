import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ADDRESS } from '@app/constants/api-path.constant';
import { Address, IAddAddress } from '@app/core/entities/address.interface';
import { environment } from '@env/environment';
import { map, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private readonly http: HttpClient) {}

  getAllAddresses(): Observable<Address[]> {
    return this.http.get<Address[]>(environment.baseUrl + API_ADDRESS);
  }

  addAddress(address: IAddAddress): Observable<Address> {
    return this.http.post<Address>(environment.baseUrl + API_ADDRESS, address);
  }

  putAddress(address: Address): Observable<Address> {
    return this.http
      .put<number>(environment.baseUrl + API_ADDRESS, address)
      .pipe(
        switchMap((id) =>
          this.getAllAddresses().pipe(map((allAddresses) => allAddresses.find((address) => address.id === id) as Address)),
        ),
      );
  }

  deleteAddress(id: number): Observable<Address> {
    return this.http.delete<Address>(environment.baseUrl + API_ADDRESS + '/' + id);
  }
}
