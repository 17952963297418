import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ORDER } from '@app/constants/api-path.constant';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class SetOrderService {
  constructor(private http: HttpClient) {}

  setOrder(productId: number, quantity: number): Observable<any> {
    return this.http.post(
      environment.baseUrl + API_ORDER,
      {},
      {
        params: {
          productId,
          quantity,
        },
      },
    );
  }
}
