import { computed, inject, untracked } from '@angular/core';
import { Order, OrderProduct } from '@app/core/entities/order.interface';
import { AuthService } from '@app/modals/auth/auth.service';
import { OrderService, useOrder } from '@app/services/order.service';
import { DeliveryRepository } from '@app/state/delivery.repository';
import { subscribe } from '@app/utils/subscribe';
import { tapResult } from '@app/utils/tap-result';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { SetOrderService } from '@services/set-order.service';
import { concatMap, forkJoin, pipe, switchMap } from 'rxjs';

export const BasketRepository = signalStore(
  { providedIn: 'root' },
  withState({
    isBasketOpen: false,
    orderId: 0,
    totalDiscount: 0,
    totalPrice: 0,
    serviceFee: 0,
  }),
  withEntities<OrderProduct>(),
  withMethods((store, orderService = inject(OrderService), setOrderService = inject(SetOrderService)) => {
    const loadBasket = rxMethod<void>(
      pipe(
        concatMap(() => orderService.getBasket()),
        tapResult((order) =>
          patchState(store, setAllEntities(order.products), {
            orderId: order.id,
            totalPrice: order.amount,
            totalDiscount: order.amountBeforeDiscount - order.amount,
            serviceFee: order.serviceFeeAmount,
          }),
        ),
      ),
    );
    const toggleBasket = (isOpen?: boolean) => {
      patchState(store, (store) => ({ isBasketOpen: isOpen === undefined ? !store.isBasketOpen : isOpen }));
    };
    const getProduct = (productId: number) => {
      return store.entities().find((product) => product.id === productId);
    };
    const selectProduct = (productId: number) => {
      return computed(() => store.entities().find((product) => product.id === productId));
    };
    const setBasket = (order: Order | { id: number; products: OrderProduct[] }) => {
      patchState(store, setAllEntities(order.products), { orderId: order.id });
    };
    const addOrderToBasket = (uuid: string) => {
      return orderService.addOrderProductsToBasket(uuid).pipe(tapResult(() => loadBasket()));
    };
    const clearBasket = () => {
      return orderService.clearBasket().pipe(tapResult(() => loadBasket()));
    };
    const updateProduct = rxMethod<OrderProduct | { id: number; quantity: number }>(
      pipe(
        switchMap((product) => {
          return setOrderService.setOrder(product.id, product.quantity);
        }),
        tapResult(() => loadBasket()),
      ),
    );
    const addProducts = (products: OrderProduct[]) => {
      return forkJoin(products.map((product) => setOrderService.setOrder(product.id, product.quantity))).pipe(
        tapResult(() => loadBasket()),
      );
    };
    const clearRestricted = () => {
      return forkJoin(
        untracked(store.entities)
          .filter((product) => product.forbiddenUnderAgeOf18)
          .map((product) => setOrderService.setOrder(product.id, 0)),
      ).pipe(tapResult(() => loadBasket()));
    };

    return {
      loadBasket,
      toggleBasket,
      getProduct,
      selectProduct,
      setBasket,
      addOrderToBasket,
      clearBasket,
      updateProduct,
      addProducts,
      clearRestricted,
    };
  }),
  withComputed((store, deliveryRepository = inject(DeliveryRepository)) => ({
    count: computed(() => store.entities().length),
    deliveryAmount: computed(() =>
      store.totalPrice() >= deliveryRepository.minimalAmountFreeDelivery() ? 0 : deliveryRepository.deliveryAmount(),
    ),
    toFreeDelivery: computed(() =>
      store.totalPrice() >= deliveryRepository.minimalAmountFreeDelivery()
        ? 0
        : (deliveryRepository.minimalAmountFreeDelivery() - store.totalPrice()).toFixed(2),
    ),
    hasRestricted: computed(() => store.entities().some((product) => product.forbiddenUnderAgeOf18)),
  })),
  withHooks((store, authService = inject(AuthService)) => ({
    onInit() {
      authService.user$.subscribe(() => store.loadBasket());
    },
  })),
);

export const useBasket = () => inject(BasketRepository);

export const registerSalesProducts = () => {
  const order = useOrder();
  const basket = useBasket();

  subscribe([
    order.getBasket().subscribe((order) => {
      basket.setBasket(order);
    }),
  ]);
};
