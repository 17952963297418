import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  API_ORDER,
  API_ORDER_CANCEL,
  API_ORDER_FINISH,
  API_ORDER_HISTORY_ORDER,
  API_ORDER_REPEAT,
  API_ORDER_UPDATE_DELIVERY,
  API_PAYMENT_CREATE,
} from '@app/constants/api-path.constant';
import { OrderHistoryOrder } from '@app/core/entities/order-history.interface';
import { Order } from '@app/core/entities/order.interface';
import { FirstPaymentStep } from '@app/core/entities/payment.interface';
import { IPayment, IPaymentPayload } from '@app/pages/payment/payment.interface';
import { toLocalDate } from '@app/utils/date-utils';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private http: HttpClient,
    private readonly sanitizer: DomSanitizer,
  ) {}

  getBasket(): Observable<Order> {
    return this.http.get<Order>(environment.baseUrl + API_ORDER);
  }

  clearBasket(): Observable<Order> {
    return this.http.delete<Order>(environment.baseUrl + API_ORDER);
  }

  finishOrder(paymentData: Omit<FirstPaymentStep, 'orderId'>): Observable<number> {
    const deliveryData = paymentData.deliveryAddress
      ? {
          addressId: paymentData.deliveryAddress.id,
          outsideTheDoor: !!paymentData.outsideTheDoor,
        }
      : {
          isPickup: true,
        };
    const params = {
      receiptAlias: paymentData.receiptAlias,
      deliveryDate: paymentData.deliveryTime && toLocalDate(paymentData.deliveryTime),
      missedProductsAction: paymentData.missedProductsAction,
      note: paymentData.note,
      phoneNumber: paymentData.phoneNumber,
      ...deliveryData,
    };
    return this.http.post<number>(environment.baseUrl + API_ORDER_FINISH, params);
  }

  getOrderDetails(id: number | string): Observable<OrderHistoryOrder> {
    return this.http.get<OrderHistoryOrder>(environment.baseUrl + API_ORDER_HISTORY_ORDER(id));
  }

  addOrderProductsToBasket(uuid: string): Observable<any> {
    return this.http.post<any>(environment.baseUrl + API_ORDER_REPEAT(uuid), {});
  }

  updateOrderDelivery(id: number, deliveryDate: Date): Observable<any> {
    return this.http.post<any>(environment.baseUrl + API_ORDER_UPDATE_DELIVERY(id), deliveryDate);
  }

  cancelOrder(uuid: string) {
    return this.http.delete(environment.baseUrl + API_ORDER_CANCEL(uuid));
  }

  createPayment(payload: IPaymentPayload): Observable<IPayment> {
    return this.http.post<IPayment>(environment.baseUrl + API_PAYMENT_CREATE, payload).pipe(
      map((frameData) => {
        return {
          ...frameData,
          url: this.sanitizer.bypassSecurityTrustResourceUrl(frameData.url as string),
        };
      }),
    );
  }
}

export const useOrder = () => inject(OrderService);
